export default function BillingAuthorization() {
    return (
        <div>
            <div className="article">
                <h2>AUTOMATIC BILLING AUTHORIZATION</h2>
                <p>
                    By signing below, upon purchasing coverage,{' '}
                    <strong>
                        I AUTHORIZE LIGHTHOUSE TO AUTOMATICALLY DEBIT MY PAYMENT
                        METHOD ON FILE FOR ANY FUTURE CHARGES RELATED TO MY
                        SECURITY DEPOSIT ALTERNATIVE (THE “RENTAL LETTER OF
                        CREDIT” OR “LOC”)
                    </strong>{' '}
                    including, but not limited to, non-refundable issuing fees,
                    coverage adjustments, coverage renewals, tenant
                    reimbursement amounts for approved claims, automated
                    installment plans, processing fees, collection fees, and all
                    other fees associated with my account.
                </p>
                <p>
                    <strong>
                        I UNDERSTAND THAT THIS PRE-AUTHORIZATION WILL REMAIN IN
                        EFFECT UNTIL ALL OF MY OUTSTANDING OBLIGATIONS TO
                        LIGHTHOUSE UNDER THE TERMS OF MY LOC HAVE BEEN
                        SATISFIED, AND THAT THESE CHARGES MAY BE PROCESSED
                        WITHOUT FURTHER NOTICE.
                    </strong>
                </p>
                <p>
                    I further understand that I may only cancel this
                    pre-authorization by providing written notice to Lighthouse
                    once my lease obligations have been fully satisfied and no
                    additional balance is due by me to Lighthouse under the
                    terms of my LOC. Any outstanding balance I owe to Lighthouse
                    can be collected by Lighthouse, using any legal means,
                    including but not limited to charging my pre-authorized
                    payment method(s), litigation, and/or utilizing 3rd party
                    collection agencies, etc. I agree to not dispute any charge
                    Lighthouse makes to my payment method that is authorized
                    under this Billing Authorization, and I will not change or
                    disable my payment method without Lighthouse’s prior written
                    consent.
                </p>
                <p>
                    I agree to be held responsible for all late fees, interest
                    fees, collection agency fees, and/or attorney fees to
                    collect any amounts due to Lighthouse under the terms of my
                    LOC. Lighthouse may report any delinquent debt they are owed
                    by me to the relevant collection bureaus and/or credit
                    reporting agencies. I understand that late payments, missed
                    payments, and other defaults on my account may be reflected
                    in my credit report which may result in my credit being
                    adversely impacted, and make it difficult for me to rent
                    other property(ies) in the future.
                </p>
                <p></p>
            </div>
        </div>
    )
}
