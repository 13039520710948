import { InfoPreview, Quote } from '../../types'
import { currencyFormatter, formatDate } from '../../utils'

interface LetterOfCreditProps {
    data: InfoPreview
    quote: Quote
}

export default function LetterOfCredit(props: LetterOfCreditProps) {
    return (
        <div>
            <div className="article">
                <h2>Rental Letter of Credit</h2>
                <table className="table">
                    <tr>
                        <th>Date Issued:</th>
                        <td>{formatDate(new Date().toDateString())}</td>
                    </tr>
                    <tr>
                        <th>Issued By:</th>
                        <td>
                            <strong>My Lighthouse</strong>
                            <br />
                            (805) 272-0003
                            <br />
                            contact@mylighthouse.co
                        </td>
                    </tr>
                    <tr>
                        <th>Beneficiary:</th>
                        <td>{props.data.propertyManager}</td>
                    </tr>
                    <tr>
                        <th>Tenant:</th>
                        <td>
                            {props.data.firstName} {props.data.lastName}
                        </td>
                    </tr>
                    {props.data.ssn && (
                        <tr>
                            <th>Tenant SSN:</th>
                            <td></td>
                        </tr>
                    )}
                    <tr>
                        <th>Rental Property:</th>
                        <td>{props.data.propertyName}</td>
                    </tr>
                    <tr>
                        <th>Letter of Credit Number:</th>
                        <td>{props.data.policyName}</td>
                    </tr>
                    <tr>
                        <th>Non-Refundable Issuing Fee:</th>
                        <td>{currencyFormatter.format(props.quote.total)}</td>
                    </tr>
                    <tr>
                        <th>Credit Amount:</th>
                        <td>
                            {currencyFormatter.format(
                                props.data.coverageAmount
                            )}
                        </td>
                    </tr>
                    <tr>
                        <th>Monthly Rent:</th>
                        <td>
                            {currencyFormatter.format(
                                props.data.monthlyRentAmount
                            )}
                        </td>
                    </tr>
                    <tr>
                        <th>Effective Date:</th>
                        <td>{formatDate(props.data.coverageStartDate)}</td>
                    </tr>
                    <tr>
                        <th>Expiration Date:</th>
                        <td>{formatDate(props.data.coverageEndDate)}</td>
                    </tr>
                </table>
                <p>
                    In consideration of creditworthiness and payment of
                    Non-Refundable Issuing Fee (“Issuing Fee”) by Jane Smith
                    (“Tenant”) to My Lighthouse (“Administrator”), We hereby
                    issue this Revocable Stand-by Letter of Credit (“LOC”),{' '}
                    {props.data.policyName}, in favor of{' '}
                    {props.data.propertyManager} (“Beneficiary” or “Landlord”)
                    for a sum not to exceed{' '}
                    {currencyFormatter.format(props.data.coverageAmount)}{' '}
                    (“Credit Amount”), guaranteed by Tenant. This LOC is given
                    as security for the benefit of Landlord who may suffer
                    damages resulting from Tenant’s failure to meet or perform
                    their obligations under their Rental Agreement with Landlord
                    for the property located at {props.data.propertyName}{' '}
                    (“Rental Property”). This LOC is issued solely for
                    Landlord’s benefit and provides payment exclusively to
                    Landlord for certain physical damages caused by Tenant to
                    the Rental Property (beyond normal wear and tear), and for
                    Loss of Rent arising from Tenant’s failure to meet rental
                    payment obligations under their Rental Agreement, up to the
                    Credit Amount only. Landlord agrees to use standards set by
                    Applicable Law to define what constitutes Damages and/or
                    Loss of Rent and what is considered Normal Wear and Tear.
                </p>
                <p>
                    Tenant agrees and commits if their Issuing Fee is disputed
                    at any time, for any reason, Tenant is responsible for
                    contacting the issuing financial institution and resolving
                    the dispute within ten (10) business days of receiving
                    notification from Administrator. Tenant shall also be
                    responsible for paying a one-time fifty ($50) dollar Dispute
                    Resolution Service Fee to Administrator within thirty (30)
                    calendar days of receiving the original notification from
                    Administrator before coverage can be reinstated. Failure to
                    resolve will result in the termination of coverage, and
                    Tenant will immediately assume full financial responsibility
                    for their Rental Agreement with Landlord.
                </p>
                <p>
                    It is a condition of this LOC that it shall be automatically
                    extended for an additional period of one (1) year on the day
                    immediately following its current Expiration Date (“Renewal
                    Date”), and each future Expiration Date, unless Landlord
                    provides written notice to Administrator at least 30 days
                    prior to then current Expiration Date to modify or cancel
                    this LOC. Issuing Fees for automatically extended coverage
                    may not increase by more than fifteen-percent (15%) in a
                    given 12-month period. TENANT AGREES ADMINISTRATOR MAY
                    AUTOMATICALLY COLLECT PAYMENT FOR RENEWED COVERAGE ON
                    TENANT’S BEHALF SEVEN (7) DAYS IN ADVANCE OF RENEWAL DATE
                    USING PRE-AUTHORIZED PAYMENT METHOD(S) ON FILE, AS PERMITTED
                    UNDER THE AUTOMATIC BILLING AUTHORIZATION (“PRE-AUTH
                    AGREEMENT”). Tenant is responsible for maintaining accurate
                    payment information at all times and must complete payment
                    in full on or before the Renewal Date to extend coverage. If
                    Tenant fails to make such payment to Administrator, Tenant
                    will then have thirty (30) calendar days to complete payment
                    in full, plus an additional twenty-five ($25) dollar
                    Reinstatement Fee to reinstate coverage. Failure to make
                    such payment within this timeframe will result in
                    termination of coverage, and Tenant will immediately assume
                    full financial responsibility for their Rental Agreement
                    with Landlord.
                </p>
                <p>
                    In the event Landlord terminates Rental Agreement with
                    Tenant prior to the then Current Expiration Date, and
                    certifies in writing they will not be filing a request for
                    payment under the terms of this LOC, Landlord may cancel LOC
                    at any time with Administrator and request a refund be
                    issued to Tenant (“Refund Request”), if eligible. If a
                    Refund Request is received by Administrator prior to
                    Coverage Start Date, Tenant is eligible for a full refund of
                    Issuing Fee, excluding processing fees. If a Refund Request
                    is received by Administrator within fourteen (14) calendar
                    days following Coverage Start Date, Tenant is eligible for a
                    prorated refund of Issuing Fee, excluding processing fees.
                    Tenant is no longer eligible for a refund if Refund Request
                    is received by Administrator more than fourteen (14)
                    calendar days following Coverage Start Date.
                </p>
                <p>
                    <b>
                        FOR ANY VALID REQUEST MADE BY LANDLORD, AND FOR WHICH A
                        LOSS PAYMENT IS MADE BY ADMINISTRATOR UNDER THIS LOC,
                        TENANT AGREES AND COMMITS THEY WILL THEN BE OBLIGATED TO
                        PAY ADMINISTRATOR THE AMOUNT OF THE REQUEST (“TENANT
                        REIMBURSEMENT AMOUNT”) NOT TO EXCEED THE CREDIT AMOUNT,
                        AND TO ABIDE BY ALL TERMS AND CONDITIONS SET FORTH IN
                        THE PRE-AUTH AGREEMENT.
                    </b>{' '}
                    <b>
                        PAYMENT OF SUCH TENANT REIMBURSEMENT AMOUNT WILL BE DUE
                        IN FULL TO ADMINISTRATOR NO LATER THAN THIRTY (30)
                        CALENDAR DAYS FROM THE INITIAL DEMAND FOR REIMBURSEMENT
                        BY, OR ON BEHALF OF, ADMINISTRATOR.&nbsp;
                    </b>
                </p>
                <p>
                    Payment of Tenant Reimbursement Amount may be completed
                    through various methods including, but not limited to,
                    pre-authorized payment method(s) on file, credit card, debit
                    card, checking account, or savings account transfers.
                    Certain methods may incur additional fees at Tenant’s
                    expense. Deferred-Interest Installment Plans (“Installment
                    Plan”) may be made available.{' '}
                    <b>
                        IF TENANT FAILS TO MAKE INITIAL PAYMENT TO ADMINISTRATOR
                        WITHIN TWENTY-ONE (21) CALENDAR DAYS OF RECEIVING
                        INITIAL DEMAND FOR TENANT REIMBURSEMENT AMOUNT, TENANT
                        AGREES ADMINISTRATOR MAY AUTOMATICALLY ENROLL TENANT IN
                        AN INSTALLMENT PLAN, CONSISTING OF NO LESS THAN TWELVE
                        (12) EQUAL MONTHLY PAYMENTS USING TENANT’S
                        PRE-AUTHORIZED PAYMENT METHOD(S) ON FILE, AS PERMITTED
                        BY THE PRE-AUTH AGREEMENT.
                    </b>{' '}
                    If Tenant’s balance is not repaid in full by the end of the
                    twelve-month period, deferred interest charges will be
                    imposed from the date of initial demand for Tenant
                    Reimbursement Amount at the variable purchase rate on
                    Tenant’s account, which is 19.99% APR. If Tenant fails to
                    make such payment to Administrator, Administrator will have
                    the right to commence a proceeding against Tenant and may
                    recover the amount due, plus all attorneys’ fees and costs
                    of collection. Such costs of collection include, but are not
                    limited to, the fees of any collection agency, which will be
                    added to the account at the time it is placed with an agency
                    for collection and may be based on a percentage at a maximum
                    of twenty-five percent (25%) of the debt, and all reasonable
                    costs and expenses, including reasonable attorneys’ fees,
                    incurred in such collection efforts.&nbsp;
                </p>
                <p>
                    Reimbursement requests on this LOC are subject to review by
                    Administrator to ensure all criteria requested by Landlord
                    fall under Applicable Law in Rental Property’s
                    jurisdiction.&nbsp;
                </p>
                <p>
                    Tenant understands and agrees that typing their name in the
                    “Authorized by” section below constitutes their electronic
                    signature, which is legally binding, and has the same
                    validity and effect as a handwritten signature. By signing
                    below, Tenant consents to use electronic means to (i) sign
                    this document, and (ii) receive any future legal notices
                    electronically.
                </p>
            </div>
        </div>
    )
}
