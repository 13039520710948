import { Link, Navigate } from 'react-router-dom'
import Steps from '../components/Steps'
import Info from '../components/Info'
import { useInfoPreviewContext } from '../context/InfoPreviewContext'
import { useQuoteContext } from '../context/QuoteContext'

export default function Preview() {
    const infoPreview = useInfoPreviewContext()?.infoPreview
    const quote = useQuoteContext()?.quote

    if (infoPreview === undefined) {
        return <Navigate to="/start" />
    }

    let nextPage = '/questions'

    if (quote) {
        if (infoPreview.connectedBankAccount) {
            if (infoPreview.paymentDisabled) {
                nextPage = '/payment-notice'
            } else {
                nextPage = '/quote'
            }
        } else {
            nextPage = '/bank-account'
        }
    }

    return (
        <>
            <Steps active={1} />
            <Info info={infoPreview} />
            <p className="align_right">
                <Link className="button" to={nextPage}>
                    Confirm
                </Link>
            </p>
        </>
    )
}
