import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Layout from './pages/Layout'
import Home from './pages/Home'
import Faq from './pages/Faq'
import Taa from './pages/Taa'
import Info from './pages/Info'
import Verify from './pages/Verify'
import Preview from './pages/Preview'
import Questions from './pages/Questions'
import Quote from './pages/Quote'
import Login from './pages/Login'
import Account from './pages/Account'
import NotFound from './pages/NotFound'
import { loadStripe } from '@stripe/stripe-js'
import { AuthProvider } from './context/AuthContext'
import { InfoPreviewContextProvider } from './context/InfoPreviewContext'
import { QuoteContextProvider } from './context/QuoteContext'
import { Config } from './config'
import './App.scss'
import { Elements } from '@stripe/react-stripe-js'
import BankAccount from './pages/BankAccount'
import PaymentDisabled from './pages/PaymentDisabled'

const stripePromise = loadStripe(Config.stripePublicKey)

export default function App() {
    return (
        <AuthProvider>
            <InfoPreviewContextProvider>
                <QuoteContextProvider>
                    <BrowserRouter>
                        <Routes>
                            <Route path="/" element={<Layout />}>
                                <Route index element={<Home />} />
                                <Route path="faq" element={<Faq />} />
                                <Route path="taa" element={<Taa />} />
                                <Route path="start" element={<Info />} />
                                <Route path="verify" element={<Verify />} />
                                <Route path="preview" element={<Preview />} />
                                <Route
                                    path="bank-account"
                                    element={<BankAccount />}
                                />
                                <Route
                                    path="payment-notice"
                                    element={<PaymentDisabled />}
                                />
                                <Route
                                    path="questions"
                                    element={<Questions />}
                                />
                                <Route path="login" element={<Login />} />
                                <Route path="account" element={<Account />} />
                                <Route
                                    path="quote"
                                    element={
                                        <Elements stripe={stripePromise}>
                                            <Quote />
                                        </Elements>
                                    }
                                />
                                <Route path="*" element={<NotFound />} />
                            </Route>
                        </Routes>
                    </BrowserRouter>
                </QuoteContextProvider>
            </InfoPreviewContextProvider>
        </AuthProvider>
    )
}
