import React, { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
    usePlaidLink,
    PlaidLinkOnSuccess,
    PlaidLinkOnEvent,
    PlaidLinkOnExit,
    PlaidLinkOptions,
} from 'react-plaid-link'
import Steps from '../components/Steps'
import { useInfoPreviewContext } from '../context/InfoPreviewContext'
import { useQuoteContext } from '../context/QuoteContext'
import ApiService from '../services/ApiService'
import { LinkTokenRequest } from '../types'

export default function BankAccount() {
    const [token, setToken] = useState<string | null>(null)
    const [error, setError] = useState<boolean>(false)
    const [processing, setProcessing] = useState<boolean>(false)
    const isOAuthRedirect = window.location.href.includes('?oauth_state_id=')
    const navigate = useNavigate()
    const infoPreview = useInfoPreviewContext()?.infoPreview
    const quote = useQuoteContext()?.quote

    React.useEffect(() => {
        if (isOAuthRedirect) {
            setToken(localStorage.getItem('link_token'))
            return
        }
        const createLinkToken = async () => {
            const response = await ApiService.post<LinkTokenRequest>(
                '/me/bank-accounts/get-link-token'
            )

            setToken(response.link_token)
            localStorage.setItem('link_token', response.link_token)
        }
        createLinkToken()
    }, [])

    const onSuccess = useCallback<PlaidLinkOnSuccess>(
        async (publicToken, metadata) => {
            setProcessing(true)
            try {
                const response = await ApiService.post<LinkTokenRequest>(
                    '/me/bank-accounts',
                    {
                        public_token: publicToken,
                        metadata: metadata,
                    }
                )

                if (infoPreview?.paymentDisabled) {
                    navigate('/payment-notice')
                } else {
                    navigate('/quote')
                }
            } catch (error) {
                setError(true)
            }
        },
        []
    )
    const onEvent = useCallback<PlaidLinkOnEvent>((eventName, metadata) => {
        console.log('PLAID EVENT', eventName, metadata)
    }, [])
    const onExit = useCallback<PlaidLinkOnExit>((error, metadata) => {
        console.log('PLAID ERROR', error, metadata)
        setError(true)
    }, [])

    const config: PlaidLinkOptions = {
        token,
        onSuccess,
        onEvent,
        onExit,
    }
    if (isOAuthRedirect) {
        config.receivedRedirectUri = window.location.href
    }

    const {
        open,
        ready,
        // error,
        // exit
    } = usePlaidLink(config)

    React.useEffect(() => {
        if (isOAuthRedirect && ready) {
            open()
        }
    }, [ready, open, isOAuthRedirect])

    return isOAuthRedirect ? (
        <></>
    ) : (
        <>
            <Steps active={1} />
            <div className="article" style={{ textAlign: 'center' }}>
                <img
                    style={{ width: '170px', margin: '0 0 10px 0' }}
                    alt=""
                    src="/assets/lighthouse-plaid.png"
                />
                <h3>Unlock Your Personalized Quote with Plaid</h3>
                <p>
                    Lighthouse has partnered with Plaid, an industry-leader in
                    financial technology, to provide you with a personalized
                    quote and a convenient, secure method of payment.
                </p>
                <p>
                    Your login credentials are never shared with us, and we only
                    access the data necessary to verify your identity, generate
                    an instant quote, and initiate payments ensuring you get the
                    coverage you need quickly, securely, and at a fair price!
                </p>
                <div className="items">
                    <div className="item">
                        <h4>Personalized Offer</h4>
                        <img alt="" src="/assets/icons/people.png" />
                    </div>
                    <div className="item">
                        <h4>Data Encryption</h4>
                        <img alt="" src="/assets/icons/verified.png" />
                    </div>
                    <div className="item">
                        <h4>Seamless Setup</h4>
                        <img alt="" src="/assets/icons/tap.png" />
                    </div>
                    <div className="item">
                        <h4>Trusted by Millions</h4>
                        <img alt="" src="/assets/icons/badge.png" />
                    </div>
                </div>
                {error && (
                    <p style={{ color: '#c00', fontSize: '0.9rem' }}>
                        There was an error connecting your account. Please try
                        again.
                        <br />
                    </p>
                )}
            </div>
            <p style={{ textAlign: 'center' }}>
                <button
                    className="button"
                    onClick={() => {
                        setError(false)
                        open()
                    }}
                    disabled={!ready || processing}
                >
                    {processing ? 'Please wait...' : 'Connect'}
                </button>
            </p>
        </>
    )
}
