import { Link, Navigate } from 'react-router-dom'
import Steps from '../components/Steps'
import { useInfoPreviewContext } from '../context/InfoPreviewContext'
import { useQuoteContext } from '../context/QuoteContext'

export default function PaymentDisabled() {
    const infoPreview = useInfoPreviewContext()?.infoPreview

    if (infoPreview === undefined) {
        return <Navigate to="/start" />
    }

    return (
        <>
            <Steps active={1} />
            <p>
                We cannot process your payment right now. Please contact us at{' '}
                <strong>(805) 272-0003</strong>
            </p>
            <p style={{ marginTop: '30px' }}>
                <Link className="button" to="/start">
                    Go Back
                </Link>
            </p>
        </>
    )
}
