import { FieldValues, useForm } from 'react-hook-form'
import { AcceptModalProps } from '../../types'
import { useState } from 'react'

export default function AcceptModal(props: AcceptModalProps) {
    const {
        register: registerInput,
        watch,
        handleSubmit: handleFormSubmit,
    } = useForm()

    const [nameMismatch, setNameMismatch] = useState<boolean>(false)
    const [accepted, setAccepted] = useState<boolean>(props.accepted)
    const name = watch('name') || props.name
    const [correctName] = useState<string>(props.correctName)
    const [timestamp] = useState<string>(props.timestamp)

    const onFormSubmit = async (data: FieldValues) => {
        if (correctName.toLocaleLowerCase() !== data.name.toLocaleLowerCase()) {
            setNameMismatch(true)
        } else {
            setNameMismatch(false)
            props.onSubmit({
                name: data.name,
                timestamp: new Date().toLocaleString(),
            })
        }
    }

    if (!props.visible) {
        return <></>
    }

    return (
        <div className="modal">
            <div className="modal-content">
                <div>{props.children}</div>
                <form
                    className="form"
                    onSubmit={handleFormSubmit(onFormSubmit)}
                >
                    <fieldset className="fieldset">
                        <label className="label">Your full name *</label>
                        <input
                            className="input"
                            type="input"
                            defaultValue={props.name}
                            {...registerInput('name', {
                                required: true,
                            })}
                        />
                    </fieldset>
                    {nameMismatch && (
                        <div
                            className="error"
                            style={{
                                background: 'none',
                                margin: '-10px 0 20px 0',
                                padding: 0,
                            }}
                        >
                            We were unable to verify the name entered. Please
                            try again.
                        </div>
                    )}
                    <fieldset className="fieldset">
                        <label className="label label--radio">
                            <input
                                type="checkbox"
                                checked={accepted}
                                required={true}
                                value="1"
                                onClick={() => {
                                    setAccepted(!accepted)
                                }}
                            />{' '}
                            By checking this box, I am confirming that I have
                            carefully read, and agree to, the above terms &
                            conditions
                        </label>
                    </fieldset>
                    <div
                        className="notice"
                        style={{ margin: '-30px 0 20px 0' }}
                    >
                        Date: {timestamp}
                    </div>
                    {name !== undefined && (
                        <p>
                            <button
                                type="submit"
                                disabled={!accepted || name.length === 0}
                                className="button"
                            >
                                Confirm
                            </button>
                        </p>
                    )}
                </form>
                <div
                    className="modal-close"
                    onClick={() => props.onClose()}
                ></div>
            </div>
        </div>
    )
}
